<template>
  <div>
    <r-combine-target
      v-if="selectedItem==='r_combine_target'"
      @reset="reset"
      @is-combined="isCombined"
      :minNumericVariable="3"
      :minSample="5"
      :nbDataset="2"
      :analysisObj="combinedAnalysis"
    />
    <r-tests-multiple v-if="combinedObjectName" v-show='selectedItem=="r_multiple_tests"'
      :dataset="combinedObjectName"
      @is-computed="isComputed"
      ref="test"
      :xNature="xNature"
      :xLogt="xLogt"
      :analysisObj="multipleAnalysis"
    ></r-tests-multiple>
    <r-posthoc-tests v-if="multipleTestObjectName" v-show='selectedItem=="r_posthoc_tests"'
      :dataset="multipleTestObjectName"
      :posthocplot="posthocplot"
      @is-posthoc-computed="isPosthocComputed"
      ref="posthoc"
      :diffVars="diffVars"
      :analysisObj="postHocAnalysis"
    ></r-posthoc-tests>
    <r-extract-diff v-if='selectedItem=="r_extract_diff"'
      :combinedObjectName="combinedObjectName"
      :multipleTestObjectName="multipleTestObjectName"
      :posthocObjectName="posthocObjectName"
      :conditionsList="conditions"
      :allVariables="allVariables"
      :signs="signs"
      ref="extract"
    ></r-extract-diff>
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'

export default {
  name: 'RDiff',
  components: {
    'r-combine-target': () => import('@/components/ranalyses/RCombineDiff.vue'),
    'r-tests-multiple': () => import('@/components/ranalyses/RTestsMultiple.vue'),
    'r-posthoc-tests': () => import('@/components/ranalyses/RPosthocTests.vue'),
    'r-extract-diff': () => import('@/components/ranalyses/RExtractDiff.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: 'Preprocessing',
          name: 'r_combine_target',
          disabled: false
        },
        {
          title: 'Multiple tests',
          name: 'r_multiple_tests',
          disabled: true
        },
        {
          title: 'Posthoc tests',
          name: 'r_posthoc_tests',
          disabled: true
        },
        {
          title: 'Extract dataset',
          name: 'r_extract_diff',
          disabled: true
        }
      ],
      combinedObjectName: null,
      multipleTestObjectName: null,
      posthocObjectName: null,
      diffVars: [],
      posthoc: 'no',
      posthocplot: 'no',
      conditions: [],
      retrievedParent: false,
      combinedAnalysis: null,
      multipleAnalysis: null,
      postHocAnalysis: null,
      allVariables: [],
      multipleSign: 'no',
      posthocSign: 'no',
      xNature: '',
      xLogt: 'no'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    signs: function () {
      return {
        'multiple': this.multipleSign,
        'posthoc': this.posthocSign
      }
    }
  },
  methods: {
    reset: function () {
      this.combinedObjectName = null
      this.combinedSuffix = null
      this.menuItems[1].disabled = true
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.multipleTestObjectName = null
      this.posthocObjectName = null
      this.diffVars = []
      this.posthoc = 'no'
      this.posthocplot = 'no'
      this.conditions = []
      this.retrievedParent = false
      this.combinedAnalysis = null
      this.multipleAnalysis = null
      this.postHocAnalysis = null
      this.multipleSign = 'no'
      this.posthocSign = 'no'
      this.xNature = ''
      this.xLogt = 'no'
    },
    isCombined: function (combinedObj) {
      this.combinedObjectName = combinedObj.combinedObjectName
      this.combinedSuffix = combinedObj.combinedDatasets.map(obj => obj.object_name).join('_')
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.multipleTestObjectName = null
      this.posthocObjectName = null
      this.diffVars = []
      this.posthoc = 'no'
      this.posthocplot = 'no'
      this.conditions = []
      this.allVariables = []
      this.xNature = combinedObj.xNature
      this.xLogt = combinedObj.xLogt
    },
    isComputed: function (vals) {
      this.menuItems[3].disabled = false
      this.multipleTestObjectName = vals.objectName
      if (vals.posthoc === 'no') {
        this.menuItems[2].disabled = true
      } else {
        this.menuItems[2].disabled = false
      }
      this.posthoc = vals.posthoc
      this.posthocplot = vals.posthocplot
      this.conditions = []
      this.diffVars = vals.diffVars
      this.allVariables = vals.allVariables
      this.multipleSign = vals.hasSign
    },
    isPosthocComputed: function (value) {
      this.posthocObjectName = value.objectName
      this.conditions = value.conditions
      this.posthocSign = value.hasSign
      this.menuItems[2].disabled = false
    },
    getChildPostHoc: function () {
      if (this.analysisObj !== null) {
        let objName = this.analysisObj.object_name
        let childPostHoc = this.workspaceStore.analyses.filter(a => a.meta.func_args.datasetName === objName)[0]
        return (childPostHoc)
      } else {
        return null
      }
    }
  },
  mounted () {
    if (this.analysisObj !== null) {
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
      if (this.analysisObj.meta.func_name === 'r_multiple_tests') {
        this.combinedObjectName = this.analysisObj.meta.func_args.datasetName
        this.multipleAnalysis = this.getAnalysisForStep('r_multiple_tests')
        this.menuStore.selectItemName(this.analysisObj.meta.func_name)
        this.combinedAnalysis = this.getParentCombined()
        if (this.posthoc === 'no') {
          this.menuItems[2].disabled = true
        } else {
          this.menuItems[2].disabled = false
        }
        this.postHocAnalysis = this.getChildPostHoc()
      }
      if (this.analysisObj.meta.func_name === 'r_posthoc_tests') {
        this.menuStore.selectItemName(this.analysisObj.meta.func_name)
        this.multipleAnalysis = this.getParentAnalysis()
        this.combinedAnalysis = this.getParentCombined(this.multipleAnalysis.meta.func_args.datasetName)
        this.combinedObjectName = this.multipleAnalysis.object_name
        this.multipleTestObjectName = this.analysisObj.meta.func_args.datasetName
        this.postHocAnalysis = this.getAnalysisForStep('r_posthoc_tests')
        this.menuItems[2].disabled = false
      }
    }
  }
}
</script>
